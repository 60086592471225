<template>

    <layout-admin-dashboard-view
        :backRoute="{name: 'admin-dashboard'}"
    >
        <template v-slot:title>
            {{translateTitleCase('entity.crud.entityListTitle')}}
        </template>

        <template v-slot:mainContent>
            <spinner v-if="asyncOps.asyncStatus.loading"></spinner>
            <block v-else>

                <div class="uk-child-width-1-2@s uk-child-width-1-4@l"
                     uk-height-match="target: > div > .uk-card"
                     uk-grid>
                    <block v-for="entity of entityList"
                           :key="entity.slug"
                           :entity="entity">
                        <router-link
                                :to="{name:'entity-list', params:{entityType:entity.slug}}"
                                custom
                                v-slot="{ navigate, href, route }">


                            <div class="uk-card uk-card-secondary uk-card-body entity-link  uk-card-hover" @click="navigate">
                                <h3 class="uk-card-title">{{safeTranslate(entity.namePlural)}}</h3>
                                <p>{{safeTranslate(entity.description)}}</p>
                            </div>
                        </router-link>

                    </block>
                </div>



            </block>
        </template>
    </layout-admin-dashboard-view>

</template>

<script>
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import { useStore } from 'vuex'
    export default {
        setup: function (props) {
            let {asyncOps, asyncOpsReady} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady}
        },
        data: function () {
            return {
                entityList : {},
                asyncData : {
                    // simple version: just route for the data
                    entityList: 'entity', // should also work with just string
/*
                    // advanced version:
                    test2: {
                        // api url
                        target : 'sample',
                        //target : 'http://localhost:3001/api/sample',
                        // data for the request. use computed() composition to make reactive
                        data : computed(() => this.dataForTest2),
                        // options for the request: method, requestAdapter, responseAdapter
                        options : {
                            method: 'post',
                            //  requestUrlFull : true // will not append prefix to target, and instead use the full url.
                            //    responseRaw: true, // allows to get the full response from server
                            //       requestAdapter : 'default', // request adapter
                            //       responseAdapter : 'raw', // response adapter
                        },

                      //  static request data exmplae
                     //      data : {
                      //     'testDataKey' : 'testDataValue'
                     //  },

                    },
*/

                }
            };
        }

    }
</script>

<style scoped lang="scss">
    .entity-link {
        cursor: pointer;
    }
</style>
